<template>
  <Page name="Industry" width="100%">
    <v-row justify="center" align="center">
      <Banner text="Industries Served" width="100%"/>
    </v-row>
    <v-row justify="center" align="center">
      <v-spacer></v-spacer>
    </v-row>
    <v-row justify="center" align="center">
      <v-divider></v-divider>
      <h2>Success stories</h2>
      <v-divider></v-divider>
    </v-row>
    <v-container style="width: 70%">
      <v-row justify="center" align="center">
        <PharmaServed/>
        <DataServed/>
        <FoodServed/>
        <ConstructionServed/>
        <MiningServed/>
        <PackagingServed/>
        <LifeScienceServed/>
        <NutritionServed/>
        <BioSciencesServed/>
        <MarineServed/>
      </v-row>
    </v-container>
  </Page>
</template>

<script>
import Page from '@/templates/Page/Page';
import Banner from '@/components/Banner';
import PharmaServed from '@/Pages/services_content/industries_served/PharmaServed';
import DataServed from '@/Pages/services_content/industries_served/DataServed';
import FoodServed from '@/Pages/services_content/industries_served/FoodServed';
import ConstructionServed from '@/Pages/services_content/industries_served/ConstructionServed';
import MiningServed from '@/Pages/services_content/industries_served/MiningServed';
import PackagingServed from '@/Pages/services_content/industries_served/PackagingServed';
import LifeScienceServed from '@/Pages/services_content/industries_served/LifeScienceServed';
import NutritionServed from '@/Pages/services_content/industries_served/NutritionServed';
import BioSciencesServed from '@/Pages/services_content/industries_served/BioSciencesServed';
import MarineServed from '@/Pages/services_content/industries_served/MarineServed';
import eventBus from '@/eventBus';


export default {
  name: 'Industry',
  metaInfo: {
    title: 'Industries',
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {
    Page,
    Banner,
    MarineServed,
    BioSciencesServed,
    NutritionServed,
    LifeScienceServed,
    PackagingServed,
    MiningServed,
    ConstructionServed,
    FoodServed,
    DataServed,
    PharmaServed,
  },
  data() {
    return {
      industries: [
        'Bio-Sciences',
        'Construction',
        'Data Centers',
        'Life Sciences',
        'Marine',
        'Mining',
        'Food',
        'Packaging',
        'Pharmaceutical',
      ],
      selected: undefined,
    };
  },
  beforeDestroy() {
    eventBus.$off('update-url');
  },
  created() {
    eventBus.$on('update-url', (text) => {
      let pathSuffix = '';
      if ((this.$route.path.split('/').length - 1 > 1)) {
        pathSuffix = text;
      } else {
        pathSuffix = `industry/${text}`;
      }
      this.$router.replace({path: `${pathSuffix}`});
    });
  },
  methods: {
    checkIndustries(value) {
      const industry = Array.from(this.industries).find((element) => element.toLowerCase() == value.toLowerCase());
      return industry;
    },
    sharedContent() {
      if (this.$route.params.id != undefined) {
        let text = this.$route.params.id;
        if (text.endsWith('2')) {
          text = text.substring(0, text.length - 1);
        }
        this.selected = this.checkIndustries(text);
        if (this.selected != undefined) {
          eventBus.$emit('dialog-activate', this.selected);
        } else {
          this.selected = '';
        }
      }
    },
  },
  mounted() {
    this.sharedContent();
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.sharedContent();
      }
    },
  },
};
</script>

<style scoped>

</style>
